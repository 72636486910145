import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    methods: {
        ...mapActions({
            saveAgent: 'agents/saveAgent',
            saveAgentImage: 'agents/saveAgentImage',
            toggleAgentsSelection: 'agents/toggleAgentsSelection',
            toggleAgentSelection: 'agents/toggleAgentSelection',
            deleteSelectedAgents: 'agents/deleteSelectedAgents',
            exportAgents: 'agents/exportAgents'
        }),

        ...mapMutations({
            selectAgent: 'agents/SET_SELECTED_AGENT'
        })
    },

    computed: {
        ...mapGetters({
            agents: 'agents/agents',
            selectedAgent: 'agents/selectedAgent',
            selectedAgents: 'agents/selectedAgents'
        })
    }
}