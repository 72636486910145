<template>
  <div id="user-wrapper">
    <div style="display: none" id="page-loading"></div>
    <div id="op-loading" v-if="isLoading">
      <div class="inner"></div>
    </div>
    <div id="main-wrapper" class="hide-side-menu">
      <UserHeader />
      <UserSideMenu />

      <div id="page-content">
        <EmailVerificationAlert />

          <div class="inner-content doc-edit container-fluid px-5">
              <router-view />
          </div>
      </div>

      <div id="bottom-push"></div>
    </div>

    <UserFooter />
  </div>
</template>

<script>
import UserHeader from "@/components/general/user-header";
import UserSideMenu from "@/components/general/user-side-menu";
import UserFooter from "@/components/general/user-footer";
import { mapGetters } from "vuex";
import EmailVerificationAlert from "@/components/alerts/email-verification";

export default {
  mounted() {
    this.$store.dispatch("wallet/fetchWallet");
  },

  computed: {
    ...mapGetters({
      isLoading: "isLoading",
    }),
  },

  components: {
    UserHeader,
    UserSideMenu,
    UserFooter,
    EmailVerificationAlert,
  },
};
</script>

<style lang="scss">
@import "../assets/css/user.scss";
</style>