<template>
  <b-modal id="agreement-modal">
    <template v-slot:modal-header="">
      <h5>العقد</h5>
    </template>

    <template v-slot:default="">
      <div class="form-group text-right">
        <label class="small">البداية:</label>

        <b-form-datepicker v-model="selectedAgreement.start" locale="ar" @context="testContext"></b-form-datepicker>
      </div>

      <div class="form-group text-right">
        <label class="small">النهاية:</label>
        <b-form-datepicker v-model="selectedAgreement.end" locale="ar"></b-form-datepicker>
      </div>

      <div class="form-group text-right">
        <label class="small">قيمة العقد:</label>
        <input
          class="form-control"
          placeholder=""
          type="number"
          v-model="selectedAgreement.totalAmount"
        />
      </div>

      <div class="form-group text-right">
        <label class="small">الكهرباء:</label>
        <input
          class="form-control"
          placeholder=""
          type="number"
          v-model="selectedAgreement.electricity"
        />
      </div>

      <div class="form-group text-right">
        <label class="small">الماء:</label>
        <input
          class="form-control"
          placeholder=""
          type="number"
          v-model="selectedAgreement.water"
        />
      </div>

      <div class="form-group text-right">
        <label class="small">المبالغ المدفوعة:</label>
        <input
          class="form-control"
          placeholder=""
          type="number"
          v-model="selectedAgreement.paidAmount"
        />
      </div>

      <div class="form-group text-right">
        <label class="small">المبالغ غير المدفوعة:</label>
        <input
          class="form-control"
          placeholder=""
          type="number"
          v-model="selectedAgreement.unpaidAmount"
        />
      </div>

      <div class="form-group text-right">
        <label class="small ml-2">طلب من محكمة التنفيذ:</label>
        <b-form-checkbox
          class="d-inline pl-0 mr-1"
          :value="true"
          v-model="selectedAgreement.isCourtRequest"
        >
        </b-form-checkbox>
      </div>

      <button type="submit" class="btn btn-primary btn-block" @click="submit">حفظ</button>

      <hr>

      <div class="form-group text-right">
        <label class="small">الدفعة الأولى:</label>

        <b-form-datepicker v-model="selectedAgreement.payment1" locale="ar"></b-form-datepicker>

        <div class="row">
          <div class="col">
            <label class="small">المدفوع</label>
            <input type="number" v-model="selectedAgreement.paid1" class="form-control">
          </div>
          <div class="col">
            <label class="small">المتبقي</label>

            <input type="number" v-model="selectedAgreement.unpaid1" class="form-control">
          </div>
        </div>
      </div>

      <div class="form-group text-right">
        <label class="small">الدفعة الثانية:</label>

        <b-form-datepicker v-model="selectedAgreement.payment2" locale="ar"></b-form-datepicker>
        
        <div class="row">
          <div class="col">
            <label class="small">المدفوع</label>
            <input type="number" v-model="selectedAgreement.paid2" class="form-control">
          </div>
          <div class="col">
            <label class="small">المتبقي</label>

            <input type="number" v-model="selectedAgreement.unpaid2" class="form-control">
          </div>
        </div>
      </div>

      <div class="form-group text-right">
        <label class="small">الدفعة الثالثة:</label>

        <b-form-datepicker v-model="selectedAgreement.payment3" locale="ar"></b-form-datepicker>

        <div class="row">
          <div class="col">
            <label class="small">المدفوع</label>
            <input type="number" v-model="selectedAgreement.paid3" class="form-control">
          </div>
          <div class="col">
            <label class="small">المتبقي</label>

            <input type="number" v-model="selectedAgreement.unpaid3" class="form-control">
          </div>
        </div>
      </div>

      <div class="form-group text-right">
        <label class="small">الدفعة الرابعة:</label>

        <b-form-datepicker v-model="selectedAgreement.payment4" locale="ar"></b-form-datepicker>

        <div class="row">
          <div class="col">
            <label class="small">المدفوع</label>
            <input type="number" v-model="selectedAgreement.paid4" class="form-control">
          </div>
          <div class="col">
            <label class="small">المتبقي</label>

            <input type="number" v-model="selectedAgreement.unpaid4" class="form-control">
          </div>
        </div>
      </div>

      <div class="form-group text-right">
        <label class="small">الدفعة الخامسة:</label>

        <b-form-datepicker v-model="selectedAgreement.payment5" locale="ar"></b-form-datepicker>

        <div class="row">
          <div class="col">
            <label class="small">المدفوع</label>
            <input type="number" v-model="selectedAgreement.paid5" class="form-control">
          </div>
          <div class="col">
            <label class="small">المتبقي</label>

            <input type="number" v-model="selectedAgreement.unpaid5" class="form-control">
          </div>
        </div>
      </div>

      <div class="form-group text-right">
        <label class="small">الدفعة السادسة:</label>

        <b-form-datepicker v-model="selectedAgreement.payment6" locale="ar"></b-form-datepicker>

        <div class="row">
          <div class="col">
            <label class="small">المدفوع</label>
            <input type="number" v-model="selectedAgreement.paid6" class="form-control">
          </div>
          <div class="col">
            <label class="small">المتبقي</label>

            <input type="number" v-model="selectedAgreement.unpaid6" class="form-control">
          </div>
        </div>
      </div>

      <div class="form-group text-right">
        <label class="small">الدفعة السابعة:</label>

        <b-form-datepicker v-model="selectedAgreement.payment7" locale="ar"></b-form-datepicker>

        <div class="row">
          <div class="col">
            <label class="small">المدفوع</label>
            <input type="number" v-model="selectedAgreement.paid7" class="form-control">
          </div>
          <div class="col">
            <label class="small">المتبقي</label>

            <input type="number" v-model="selectedAgreement.unpaid7" class="form-control">
          </div>
        </div>
      </div>

      <div class="form-group text-right">
        <label class="small">الدفعة الثامنة:</label>

        <b-form-datepicker v-model="selectedAgreement.payment8" locale="ar"></b-form-datepicker>

        <div class="row">
          <div class="col">
            <label class="small">المدفوع</label>
            <input type="number" v-model="selectedAgreement.paid8" class="form-control">
          </div>
          <div class="col">
            <label class="small">المتبقي</label>

            <input type="number" v-model="selectedAgreement.unpaid8" class="form-control">
          </div>
        </div>
      </div>

      <div class="form-group text-right">
        <label class="small">الدفعة التاسعة:</label>

        <b-form-datepicker v-model="selectedAgreement.payment9" locale="ar"></b-form-datepicker>

        <div class="row">
          <div class="col">
            <label class="small">المدفوع</label>
            <input type="number" v-model="selectedAgreement.paid9" class="form-control">
          </div>
          <div class="col">
            <label class="small">المتبقي</label>

            <input type="number" v-model="selectedAgreement.unpaid9" class="form-control">
          </div>
        </div>
      </div>

      <div class="form-group text-right">
        <label class="small">الدفعة العاشرة:</label>

        <b-form-datepicker v-model="selectedAgreement.payment10" locale="ar"></b-form-datepicker>
        
        <div class="row">
          <div class="col">
            <label class="small">المدفوع</label>
            <input type="number" v-model="selectedAgreement.paid10" class="form-control">
          </div>
          <div class="col">
            <label class="small">المتبقي</label>

            <input type="number" v-model="selectedAgreement.unpaid10" class="form-control">
          </div>
        </div>
      </div>

      <div class="form-group text-right">
        <label class="small">الدفعة الحادية عشر:</label>

        <b-form-datepicker v-model="selectedAgreement.payment11" locale="ar"></b-form-datepicker>

        <div class="row">
          <div class="col">
            <label class="small">المدفوع</label>
            <input type="number" v-model="selectedAgreement.paid11" class="form-control">
          </div>
          <div class="col">
            <label class="small">المتبقي</label>

            <input type="number" v-model="selectedAgreement.unpaid11" class="form-control">
          </div>
        </div>
      </div>

      <div class="form-group text-right">
        <label class="small">الدفعة الثانية عشر:</label>

        <b-form-datepicker v-model="selectedAgreement.payment12" locale="ar"></b-form-datepicker>
        
        <div class="row">
          <div class="col">
            <label class="small">المدفوع</label>
            <input type="number" v-model="selectedAgreement.paid12" class="form-control">
          </div>
          <div class="col">
            <label class="small">المتبقي</label>

            <input type="number" v-model="selectedAgreement.unpaid12" class="form-control">
          </div>
        </div>
      </div>
    </template>

    <template v-slot:modal-footer="">
      <button type="submit" class="btn btn-primary" @click="submit">حفظ</button>
      <a href="#" class="text-muted" @click="$bvModal.hide('agreement-modal')"
        >إغلاق</a
      >
    </template>
  </b-modal>
</template>

<script>
import AgreementsMixin from "@/mixins/agreements";
// import moment from '@/moment'

export default {
  mixins: [AgreementsMixin],

  methods: {
    submit() {
        this.updateAgreement().then((res) => {
            if (res.success) {
                this.$bvModal.hide('agreement-modal')
            }
        })
    },

    testContext (val) {
      // console.log(val.activeYMD)
      // console.log(this.moment(String(val.activeYMD)))
      // console.log(this.moment(this.moment(String(val.activeYMD))).add(1, 'year').locale('en').format('Y-MM-DD'))
      this.selectedAgreement.end = this.moment(this.moment(String(val.activeYMD))).add(11, 'months').locale('en').format('Y-MM-DD')
      this.selectedAgreement.payment1 = this.selectedAgreement.start
      this.selectedAgreement.payment12 = this.selectedAgreement.end

      this.selectedAgreement.payment2 = this.moment(this.moment(String(val.activeYMD))).add(1, 'months').locale('en').format('Y-MM-DD')
      this.selectedAgreement.payment3 = this.moment(this.moment(String(this.selectedAgreement.payment2))).add(1, 'months').locale('en').format('Y-MM-DD')
      this.selectedAgreement.payment4 = this.moment(this.moment(String(this.selectedAgreement.payment3))).add(1, 'months').locale('en').format('Y-MM-DD')
      this.selectedAgreement.payment5 = this.moment(this.moment(String(this.selectedAgreement.payment4))).add(1, 'months').locale('en').format('Y-MM-DD')
      this.selectedAgreement.payment6 = this.moment(this.moment(String(this.selectedAgreement.payment5))).add(1, 'months').locale('en').format('Y-MM-DD')
      this.selectedAgreement.payment7 = this.moment(this.moment(String(this.selectedAgreement.payment6))).add(1, 'months').locale('en').format('Y-MM-DD')
      this.selectedAgreement.payment8 = this.moment(this.moment(String(this.selectedAgreement.payment7))).add(1, 'months').locale('en').format('Y-MM-DD')
      this.selectedAgreement.payment9 = this.moment(this.moment(String(this.selectedAgreement.payment8))).add(1, 'months').locale('en').format('Y-MM-DD')
      this.selectedAgreement.payment10 = this.moment(this.moment(String(this.selectedAgreement.payment9))).add(1, 'months').locale('en').format('Y-MM-DD')
      this.selectedAgreement.payment11 = this.moment(this.moment(String(this.selectedAgreement.payment10))).add(1, 'months').locale('en').format('Y-MM-DD')
      // console.log(this.moment(this.moment(val.activeYMD)).add(1, 'years'))
    }
  },

  // watch: {
  //   'selectedAgreement.start': (val) => {
  //     console.log(val)
  //     console.log(this.moment(val).add('1', 'year'))
  //     // this.selectedAgreement.end = this.moment(val).add('1', 'year')
  //   }
  // }
};
</script>