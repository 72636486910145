import axios from '@/axios'

export default {
    namespaced: true,

    state: {
        notifications: []
    },

    getters: {
        notifications: state => state.notifications
    },

    mutations: {
        SET_NOTIFICATIONS: (state, payload) => state.notifications = payload
    },

    actions: {
        async saveNotification({ rootState }, payload) {
            if (rootState.agents.selectedAgent) payload.user_id = rootState.agents.selectedAgent.id
            if (rootState.clients.selectedClient) payload.user_id = rootState.clients.selectedClient.id

            let formData = new FormData()
            if (rootState.agents.selectedAgent) formData.append('user_id', String(rootState.agents.selectedAgent.id))
            if (rootState.clients.selectedClient) formData.append('user_id', rootState.clients.selectedClient.id)

            formData.append('isAllClients', payload.isAllClients ? 1 : 0)
            formData.append('isAllAgents', payload.isAllAgents ? 1 : 0)
            formData.append('body', payload.body)

            for (let i = 0; i < payload.files.length; i++) {
                formData.append('files[' + i + ']', payload.files[i])
            }

            let { data } = await axios.post('admin/notifications', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })

            return data
        }
    }
}