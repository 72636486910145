<template>
  <b-modal id="notification-modal">
    <template v-slot:modal-header="">
      <h5>الإشعار</h5>
    </template>

    <template v-slot:default="">
      <div v-if="!selectedAgent && !selectedClient">
          <div class="form-group">
        <b-form-checkbox
          class="d-inline pl-0 mr-1"
          :value="true"
          v-model="notification.isAllClients"
        >
        <span>لكل العملاء</span>
        </b-form-checkbox>
      </div>

      <div class="form-grou">
          <b-form-checkbox
          class="d-inline pl-0 mr-1"
          :value="true"
          v-model="notification.isAllAgents"
        >
        <span>لكل الموظفين</span>
        </b-form-checkbox>
      </div>
      </div>

      <div v-else>
        <span>إشعار للمستخدم </span><span>{{ selectedAgent ? selectedAgent.name : selectedClient.name }}</span>
      </div>

      <div class="form-group mt-3 text-right">
        <label class="small">المحتوى:</label>
        <textarea
          class="form-control"
          placeholder="المحتوى"
          v-model="notification.body"
        ></textarea>
      </div>

      <div class="form-g">
          <input type="file" name="files" ref="files" value="الملفات" @change="notification.files = $event.target.files" multiple>
      </div>
    </template>

    <template v-slot:modal-footer="">
      <button type="submit" class="btn btn-primary" @click="submit">حفظ</button>
      <a
        href="#"
        class="text-muted"
        @click="$bvModal.hide('notification-modal')"
        >إغلاق</a
      >
    </template>
  </b-modal>
</template>

<script>
import AgentsMixin from "@/mixins/agents";
import ClientsMixin from "@/mixins/clients";
import NotificationsMixin from "@/mixins/notifications";

export default {
  mixins: [AgentsMixin, ClientsMixin, NotificationsMixin],

  data() {
    return {
      notification: {
        user_id: null,
        isAllClients: false,
        isAllAgents: false,
        body: null,
        files: []
      },
    };
  },

  mounted() {
  },

  methods: {
    submit() {
        this.saveNotification(this.notification).then((res) => {
            this.showSwal(res)
            if (res.success) {
                this.$bvModal.hide('notification-modal')
            }
        })
    },

    updateImage(e) {
      this.$refs.idCardPreview.src = URL.createObjectURL(e.target.files[0]);
    },
  },
};
</script>