import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
require('@/store/subscriber')

// fontawesome
require('./font-awesome')



// jquery
Vue.prototype.$ = require('jquery');
window.$ = require('jquery')


import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)

window.Popper = require('popper.js').default;
window.$ = window.jQuery = require('jquery');

require('bootstrap');



// vue-sweetAlert2
require('./vue-sweetalert2')


require('@/interceptor')


// vue-meta
import VueMeta from 'vue-meta'
Vue.use(VueMeta)


// vue-lazyload
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
    preLoad: 1.3,
    loading: process.env.VUE_APP_ADMIN_URL + '/assets/images/logo.png',
    error: process.env.VUE_APP_ADMIN_URL + '/assets/images/image-placeholder.png',
})


// google maps
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyBQfEj4JRIhqQSyRZ2QQu5NMtIPgq-S6Ps',
        // libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    }
})


// arabic numbers component
import ArabicNumbers from '@/components/general/arabic-number'
Vue.component('ArabicNumber', ArabicNumbers)


// translations component
import Translate from '@/components/general/translate'
Vue.component('Translate', Translate)


// filepond
require('./filepond')


// global mixin
require('@/global-mixin')


// money component
import money from '@/components/general/money'
Vue.component('money', money)


// images-wrapper component
import ImagesWrapper from '@/components/general/images-wrapper'
Vue.component('ImagesWrapper', ImagesWrapper)


// vue-multiselect
import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)
require('vue-multiselect/dist/vue-multiselect.min.css')


// vue-swatches (color picker)
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'
Vue.component('v-swatches', VSwatches)



// vue quill
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
// import 'quill/dist/quill.bubble.css' // for bubble theme
Vue.use(VueQuillEditor, {
    placeholder: 'اكتب نصًّا',
    theme: 'snow',
    // format: {
    //     direction: 'rtl',
    //     align: 'right'
    // },
    modules: {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['blockquote', 'code-block'],

            [{ 'header': 1 }, { 'header': 2 }], // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
            [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
            // [{ 'direction': 'rtl' }], // text direction

            // [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': ['right', 'center', false] }],

            ['clean']
        ]
    }
})


Vue.config.productionTip = false

store.dispatch('auth/attempt', localStorage.getItem('admin_token')).then(() => {
    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app')

    // store.dispatch('init')
})