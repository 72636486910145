<template>
  <div class="panel">
    <div class="header">
      <b-form-checkbox
        v-if="canSelectAll"
        class="d-inline pl-0 mr-1"
        :value="true"
        v-model="isAllSelected"
        @change.native="$emit('toggle-items-selection')"
      >
      </b-form-checkbox>
      <span>{{ title }}</span>
      <div
        class="actions-wrapper float-left pointer"
        :class="{ 'text-red': selectedItems.length > 0 }"
        @click="$emit('delete-selected')"
      >
        <fa icon="trash" />
      </div>
    </div>

    <div class="body">
      <div v-if="items.length == 0" class="d-flex justify-content-center align-items-center p-3">
        <p class="text-muted">لا توجد بيانات</p>
      </div>

      <ul v-else>
        <slot />
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    selectedItems() {
      return this.items.filter((i) => i.selected === true);
    },
  },

  // props: ["title", "items", "isAllSelected", "showSelectingAll"],
  props: {
    title: String,
    items: Array,
    isAllSelected: Boolean,
    canSelectAll: {
      type: Boolean,
      default: true
    }
  }
};
</script>