import axios from '@/axios'

export default {
    namespaced: true,

    state: {
        settings: null,
        frontImages: []
    },

    getters: {
        settings: state => state.settings,
        frontImages: state => state.frontImages
    },

    mutations: {
        SET_SETTINGS: (state, payload) => state.settings = payload,
        SET_FRONT_IMAGES: (state, payload) => state.frontImages = payload,
    },

    actions: {
        async fetchSettings({ commit }) {
            let { data } = await axios.get('admin/settings')
            commit('SET_SETTINGS', data.settings)
        },

        async updateSettings({ getters }) {
            let { data } = await axios.put('admin/settings', getters.settings)

            return data
        },

        async fetchFrontImages({ commit }) {
            let { data } = await axios.get('admin/frontImages')

            commit('SET_FRONT_IMAGES', data.frontImages)
        },

        async deleteImage(_, image) {
            let { data } = await axios.delete(`admin/images/${image.id}`)

            return data
        },

        async uploadImages({ dispatch }, images) {
            if (!images) return { success: true, message: 'تم الحفظ بنجاح' }
            let formData = new FormData()
            for (let i = 0; i < images.length; i++) {
                formData.append('images[' + i + ']', images[i])
            }
            formData.append('isFront', 1)
            let { data } = await axios.post('admin/images', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })

            if (data.success) {
                dispatch('fetchFrontImages')
            }

            return data
        },

        async updateLeaseRequestSettings({ state }) {
            let { data } = await axios.post('admin/updateLeaseRequestSettings', state.settings)

            return data
        }
    }
}