<template>
  <b-modal id="request-modal" title="طلبات الزوار" size="lg">
    <div v-if="selectedRequest" class="container py-2">
      <div class="form-group row">
        <label for="nameAr" class="text-muted">حدد الموظف</label>

        <multiselect
          dir="rtl"
          class="text-right"
          v-model="selectedRequest.agent"
          :options="agents"
          placeholder="حدد الموظف"
          label="name"
          track-by="name"
          selectLabel="اضغظ Enter للتحديد"
          deselectLabel="اضغط Enter لإلغاء التحديد"
          autofocus
        >
          <template><span slot="noResult">لا توجد نتائج</span></template>
          <template><span slot="noOptions">لا توجد خيارات</span></template>
        </multiselect>
      </div>

      <div class="form-group row">
        <label class="text-muted">الاسم</label>
        <input type="text" class="form-control" v-model="selectedRequest.name" />
      </div>

      <div class="form-group row">
        <label class="text-muted">رقم الجوال أو البريد الإلكتروني</label>
        <input type="text" class="form-control" v-model="selectedRequest.contact" />
      </div>

      <div class="form-group row">
        <label class="text-muted">تفاصيل الطلب</label>
        <textarea class="form-control" v-model="selectedRequest.body" rows="5"></textarea>
      </div>

      <div class="form-group">
        <label for="nameAr" class="text-muted">إرفاق صور</label>
          <ImagesWrapper
            :images="allImages"
            @images-changed="updateImages($event)"
            @delete-image="deleteImageTap($event)"
          />
      </div>
    </div>

    <template v-slot:modal-footer>
      <div class="w-100">
        <div class="float-right">
          <b-button
            variant="primary"
            size="md"
            style="position: relative"
            @click="submit"
          >
            <i class="fa fa-check"></i>
            <span>حفظ</span>
          </b-button>
        </div>
        <button
          @click="$bvModal.hide('request-modal')"
          class="float-left btn btn-light"
          style="box-shadow: none"
        >
          تراجع
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import RequestsMixin from "@/mixins/requests";
import AgentsMixin from "@/mixins/agents";

export default {
  mixins: [RequestsMixin, AgentsMixin],

  data() {
    return {
      form: {
        name: null,
        contact: null,
        body: null,
      },

      newImages: []
    };
  },

  mounted() {
    this.selectedRequest.agent = this.agents.find(
      (a) => a.id == this.selectedRequest.agent_id
    );
  },

  methods: {
    submit() {
      if (!this.selectedRequest.agent) {
        this.showSwal({
          success: false,
          message: 'فضلًا قم بتحديد الموظف المطلوب'
        })
      }
      
      this.updateRequest(this.newImages).then((res) => {
        if (res.success) this.$bvModal.hide("request-modal");
      });
    },

    updateImages(files) {
      files.forEach((file) => {
        // file.src = URL.createObjectURL(file);
        this.newImages.push(file);
      });
    },

    deleteImageTap(image) {
      // console.log(image)
      let foundImage = this.selectedRequest.images.find((img) => img == image);

      if (foundImage) {
        this.deleteImage(image).then((res) => {
          if (res.success) {
            this.selectedRequest.images.splice(this.selectedRequest.images.indexOf(image), 1);
          }
        });
      } else {
        this.newImages.splice(this.newImages.indexOf(image), 1);
      }
    },
  },

  computed: {
    allImages() {
      this.newImages.forEach((newImage) => {
        newImage.src = URL.createObjectURL(newImage);
      });
      return this.selectedRequest.images.concat(this.newImages);
    },
  },
};
</script>