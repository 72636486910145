import { mapActions, mapGetters } from 'vuex';

export default {
    methods: {
        ...mapActions({
            updateSettings: 'settings/updateSettings',
            deleteImage: 'settings/deleteImage',
            uploadImages: 'settings/uploadImages',
            updateLeaseRequestSettings: 'settings/updateLeaseRequestSettings'
        })
    },

    computed: {
        ...mapGetters({
            settings: 'settings/settings',
            frontImages: 'settings/frontImages'
        })
    }
}