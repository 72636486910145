import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    methods: {
        ...mapActions({
            updateAd: 'ads/updateAd',
            saveAd: 'ads/saveAd',
            deleteImage: 'ads/deleteImage',
            uploadImages: 'ads/uploadImages',
            deleteSelectedAds: 'ads/deleteSelectedAds',
            toggleAdsSelection: 'ads/toggleAdsSelection',
            toggleAdSelection: 'ads/toggleClientSelection',
        }),

        ...mapMutations({
            selectAd: 'ads/SET_SELECTED_AD'
        })
    },

    computed: {
        ...mapGetters({
            ads: 'ads/ads',
            selectedAd: 'ads/selectedAd',
            selectedAds: 'ads/selectedAds',
        })
    }
}