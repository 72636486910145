<template>
  <div class="container">
    <div class="panel mb-5">
      <div class="header">
        <span>بيانات التواصل</span>
      </div>

      <div class="body p-4">
        <div class="text-center w-100 m-auto">
          <!-- <div class="form-group text-right">
            <label class="small">سناب شات:</label>
            <input
              class="form-control"
              placeholder="سناب شات"
              v-model="settings.snapchat"
            />
          </div> -->

          <div class="form-group mt-3 text-right">
            <label class="small">رقم الجوال:</label>
            <input
              class="form-control"
              placeholder="رقم الجوال"
              type="text"
              v-model="settings.mobile"
            />
          </div>

          <div class="form-group mt-3 text-right">
            <label class="small">البريد الإلكتروني:</label>
            <input
              class="form-control"
              placeholder="البريد الإلكتروني"
              type="email"
              v-model="settings.email"
            />
          </div>
        </div>

        <button class="btn btn-block btn-primary mt-5" @click="updateSettings">
          <fa icon="save" class="ml-2" />
          <span>حفظ</span>
        </button>
      </div>
    </div>

    <div class="panel mb-5">
      <div class="header">
        <span>صور الواجهة</span>
      </div>

      <div class="body p-4">
        <ImagesWrapper
          :images="allImages"
          @images-changed="updateImages($event)"
          @delete-image="deleteImageTap($event)"
        />

        <button class="btn btn-block btn-primary mt-5" @click="saveImagesTap">
          <fa icon="save" class="ml-2" />
          <span>حفظ</span>
        </button>
      </div>
    </div>

    <div class="panel mb-5">
      <div class="header">
        <span>أقسام العقارات</span>
      </div>

      <div class="body p-4">
        <div class="w-100 m-auto">
          <div class="text-right mb-3">
            <button class="btn btn-primary btn-sm" @click="addCategory">
              <fa icon="plus" class="ml-2" />
              <span>إضافة قسم</span>
            </button>
          </div>

          <div v-for="(cat, i) in categories" :key="i" class="form-group">
            <div class="row">
              <div class="col-12 col-md-3">
                <input
                  type="text"
                  class="form-control d-inline"
                  v-model="cat.name"
                  placeholder="اسم القسم"
                />
              </div>

              <div class="col-3 col-md-3 bg-light p-1">
                الخلفية
                <v-swatches
                style="border-bottom: 2px solid rgba(0,0,0,.3)"
                  v-model="cat.color"
                  show-fallback
                  fallback-input-type="color"
                  popover-x="left"
                ></v-swatches>
              </div>

              <div class="col-3 col-md-3 bg-light p-1">
                النص
                <v-swatches
                style="border-bottom: 2px solid rgba(0,0,0,.3)"
                  v-model="cat.textColor"
                  show-fallback
                  fallback-input-type="color"
                  popover-x="left"
                ></v-swatches>
              </div>

              <div class="col-2 col-md-2">
                <div class="d-inline mr-2">
                  <button
                    class="btn btn-danger btn-sm"
                    @click="deleteCategory(cat)"
                    style="position: relative; top: 15px;"
                  >
                    <fa icon="trash" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          class="btn btn-block btn-primary mt-5"
          @click="updateCategories"
        >
          <fa icon="save" class="ml-2" />
          <span>حفظ</span>
        </button>
      </div>
    </div>

    <!-- <b-card no-body class="mb-5">
      <b-tabs card>
        <b-tab title="العقد السكني">
          <b-card-title class="small"
            >شقق - عمائر - فلل - استراحات - أراضي</b-card-title
          >
          <b-card-text>
            <quill-editor
              dir="rtl"
              ref="quill1"
              v-model="settings.lease1"
              @ready="initQuill1"
            />
          </b-card-text>
        </b-tab>

        <b-tab title="العقد التجاري">
          <b-card-title class="small">المحلات - الصالات - المكاتب</b-card-title>
          <b-card-text>
            <quill-editor
              dir="rtl"
              ref="quill2"
              v-model="settings.lease2"
              @ready="initQuill2"
            />
          </b-card-text>
        </b-tab>

        <b-tab title="العقد بالباطن">
          <b-card-title class="small"></b-card-title>
          <b-card-text>
            <quill-editor
              dir="rtl"
              ref="quill3"
              v-model="settings.lease3"
              @ready="initQuill3"
            />
          </b-card-text>
        </b-tab>
      </b-tabs>

      <b-card-footer>
        <button
          class="btn btn-block btn-primary"
          @click="updateLeaseRequestSettings"
        >
          <fa icon="save" class="ml-2" />
          <span>حفظ</span>
        </button>
      </b-card-footer>
    </b-card> -->

    <div class="panel mb-5">
      <div class="header">
        <span>بيانات الإدارة</span>
      </div>

      <div class="body p-4">
        <div class="text-center w-100 m-auto">
          <!-- <div class="form-group text-right">
            <label class="small">سناب شات:</label>
            <input
              class="form-control"
              placeholder="سناب شات"
              v-model="settings.snapchat"
            />
          </div> -->

          <div class="form-group mt-3 text-right">
            <label class="small">رقم الجوال:</label>
            <input
              class="form-control"
              placeholder="رقم الجوال"
              type="text"
              v-model="user.mobile"
            />
          </div>

          <div class="form-group mt-3 text-right">
            <label class="small">كلمة المرور:</label>
            <input
              class="form-control"
              placeholder="كلمة المرور"
              type="password"
              v-model="user.password"
            />
          </div>

          <div class="form-group mt-3 text-right">
            <label class="small">تأكيد كلمة المرور:</label>
            <input
              class="form-control"
              placeholder="تأكيد كلمة المرور"
              type="password"
              v-model="user.password_confirmation"
            />
          </div>
        </div>

        <button class="btn btn-block btn-primary mt-5" @click="updateAdminData">
          <fa icon="save" class="ml-2" />
          <span>حفظ</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SettingsMixin from "@/mixins/settings";
import CategoriesMixin from "@/mixins/categories";
import AuthMixin from "@/mixins/auth";

export default {
  mixins: [SettingsMixin, CategoriesMixin, AuthMixin],

  data() {
    return {
      newImages: [],
      content: "",
    };
  },

  mounted() {
    // this.$refs.quill1.quill.format('direction', 'rtl')
    // this.$refs.quill1.quill.format('align', 'right')
  },

  methods: {
    initQuill1() {
      this.$refs.quill1.quill.format("direction", "rtl");
      this.$refs.quill1.quill.format("align", "right");
    },

    initQuill2() {
      this.$refs.quill2.quill.format("direction", "rtl");
      this.$refs.quill2.quill.format("align", "right");
    },

    initQuill3() {
      this.$refs.quill3.quill.format("direction", "rtl");
      this.$refs.quill3.quill.format("align", "right");
    },

    saveImagesTap() {
      this.uploadImages(this.newImages).then((res) => {
        if (res.success) {
          this.newImages = [];
        }
      });
    },

    updateImages(files) {
      files.forEach((file) => {
        this.newImages.push(file);
      });
    },

    deleteImageTap(image) {
      // console.log(image)
      let foundImage = this.frontImages.find((img) => img == image);

      if (foundImage) {
        this.deleteImage(image).then((res) => {
          if (res.success) {
            this.frontImages.splice(this.frontImages.indexOf(image), 1);
          }
        });
      } else {
        foundImage = this.newImages.find((img) => img == image);
        this.newImages.splice(this.newImages.indexOf(image), 1);
      }
    },
  },

  computed: {
    allImages() {
      this.newImages.forEach((newImage) => {
        newImage.src = URL.createObjectURL(newImage);
      });
      return this.frontImages.concat(this.newImages);
    },
  },
};
</script>