import { mapActions, mapGetters } from 'vuex';

export default {
    methods: {
        ...mapActions({
            storeHash: 'hashmanager/storeHash'
        })
    },

    computed: {
        ...mapGetters({

        })
    }
}