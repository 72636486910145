import axios from '@/axios'

export default {
    namespaced: true,

    state: {
        commissions: [],
        selectedCommission: null
    },

    getters: {
        commissions: state => state.commissions,
        selectedCommission: state => state.selectedCommission,
        selectedCommissions: state => {
            return state.commissions.filter(d => d.selected)
        }
    },

    mutations: {
        SET_COMMISSIONS: (state, payload) => state.commissions = payload,
        SET_SELECTED_COMMISSION: (state, payload) => state.selectedCommission = payload,
        SELECT_ALL: state => {
            state.commissions.forEach(m => m.selected = true)
        },
        DESELECT_ALL: state => {
            state.commissions.forEach(m => m.selected = false)
        },
        SELECT_COMMISSION: (state, payload) => {
            state.commissions[state.commissions.indexOf(payload)].selected = true
        },

        DESELECT_COMMISSION: (state, payload) => {
            state.commissions[state.commissions.indexOf(payload)].selected = false
        },
    },

    actions: {
        async fetchCommissions({ commit }) {
            let { data } = await axios.get('admin/commissions')
            data.commissions.forEach(m => m.selected = false)
            commit('SET_COMMISSIONS', data.commissions)

        },

        toggleCommissionsSelection({ commit, getters, state }) {
            if (getters.selectedCommissions.length < state.commissions.length) {
                commit('SELECT_ALL')
            } else {
                commit('DESELECT_ALL')
            }
        },

        toggleCommissionSelection({ commit, state }, payload) {
            let currentState = state.commissions[state.commissions.indexOf(payload)].selected
            if (currentState) {
                commit('SELECT_COMMISSION', payload)
            } else {
                commit('DESELECT_COMMISSION', payload)
            }
        },

        async deleteSelectedCommissions({ getters, dispatch }) {
            let ids = []
            getters.selectedCommissions.forEach(doc => ids.push(doc.id))
            let { data } = await axios.delete('admin/commissions', { params: { ids } })
            dispatch('fetchCommissions')
            return data
        },

        async saveCommission({ commit, dispatch, getters }, payload) {
            if (payload.agent) payload.agent_id = payload.agent.id
                // if (payload.agreement) payload.agreement_id = payload.agreement.id

            if (getters.selectedCommission && (!payload.agent || !payload.agreement)) {
                return { success: false, message: 'فضلًا تحقق من البيانات' }
            }

            let url = getters.selectedCommission ? `admin/commissions/${getters.selectedCommission.id}` : `admin/commissions`
            let { data } = await axios({
                method: getters.selectedCommission ? 'put' : 'post',
                url,
                data: payload
            })

            if (data.success) {
                dispatch('fetchCommissions')
                commit('SET_SELECTED_COMMISSION', data.commission)
            }

            return data
        },
    }
}