<template>
  <div class="">
    <div class="text-right mb-3">
      <button class="btn btn-sm btn-primary" @click="addCommissionTap">
        <fa icon="plus" class="ml-2" />
        <span>إضافة عمولة</span>
      </button>
    </div>

    <PanelList
      :items="commissions"
      title="العمولات"
      :isAllSelected="isAllSelected"
      @toggle-items-selection="toggleCommissionsSelection()"
      @delete-selected="deleteSelectedCommissions()"
    >
      <CommissionsPanelItem
        v-for="(commission, i) in commissions"
        :key="i"
        :commission="commission"
      />
    </PanelList>

    <CommissionModal />
  </div>
</template>

<script>
import PanelList from "@/components/general/panel-list";
import CommissionsPanelItem from "@/components/panel-items/commission";
import CommissionModal from "@/components/modals/commission";
import CommissionsMixin from '@/mixins/commissions'
import AgentsMixin from '@/mixins/agents'

export default {
  mixins: [CommissionsMixin, AgentsMixin],

  methods: {
    addCommissionTap () {
      this.selectCommission(null)
      this.selectAgent(null),
      this.$bvModal.show('commission-modal')
    }
  },

  computed: {
    isAllSelected () {
      return this.commissions.length == this.selectedCommissions.length
    }
  },

  components: {
    PanelList,
    CommissionsPanelItem,
    CommissionModal,
  },
};
</script>