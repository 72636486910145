<template>
  <div id="guest-wrapper">
    <div style="display: none;" id="page-loading"></div>
    <div id="main-wrapper">
      <GuestNavbar />

      <router-view />

      <div id="bottom-push"></div>
    </div>

    <!-- <GuestFooter /> -->
  </div>
</template>

<script>
import GuestNavbar from "@/components/general/guest-navbar";
// import GuestFooter from "@/components/general/guest-footer";

export default {
  components: {
    GuestNavbar,
    // GuestFooter,
  },
};
</script>

<style lang="scss">
@import '../assets/css/guest.scss';
</style>