import { mapGetters, mapMutations } from 'vuex';

export default {
    methods: {
        ...mapMutations({
            setIsLoading: 'SET_IS_LOADING',
            toggleSideMenu: 'TOGGLE_SIDE_MENU'
        })
    },

    computed: {
        ...mapGetters({
            isLoading: 'isLoading',
            showSideMenu: 'showSideMenu',
            settings: 'settings'
        })
    }
}