import axios from '@/axios'

export default {
    namespaced: true,

    state: {
        records: [],
        selectedRecord: null,
    },

    getters: {
        records: state => state.records,
        selectedRecord: state => state.selectedRecord,
        selectedRecords: state => {
            return state.records.filter(m => m.selected)
        },
    },

    mutations: {
        SET_RECORDS: (state, payload) => state.records = payload,
        SET_SELECTED_RECORD: (state, payload) => state.selectedRecord = payload,
        SELECT_ALL: state => {
            state.records.forEach(m => m.selected = true)
        },
        DESELECT_ALL: state => {
            state.records.forEach(m => m.selected = false)
        },
        SELECT_RECORD: (state, payload) => {
            state.records[state.records.indexOf(payload)].selected = true
        },

        DESELECT_RECORD: (state, payload) => {
            state.records[state.records.indexOf(payload)].selected = false
        },
    },

    actions: {
        async fetchRecords({ commit }) {
            let { data } = await axios.get('admin/leaseRequests')
            data.leaseRequests.forEach(m => m.selected = false)
            commit('SET_RECORDS', data.leaseRequests)
        },

        toggleRecordsSelection({ commit, getters, state }) {
            if (getters.selectedRecords.length < state.records.length) {
                commit('SELECT_ALL')
            } else {
                commit('DESELECT_ALL')
            }
        },

        toggleRecordSelection({ commit, state }, payload) {
            let currentState = state.records[state.records.indexOf(payload)].selected
            if (currentState) {
                commit('SELECT_RECORD', payload)
            } else {
                commit('DESELECT_RECORD', payload)
            }
        },

        async deleteSelectedRecords({ getters, dispatch }) {
            let ids = []
            getters.selectedRecords.forEach(doc => ids.push(doc.id))
            let { data } = await axios.delete('admin/leaseRequests', { params: { ids } })
            dispatch('fetchRecords')
            return data
        },

        async saveRecord({ commit, dispatch, getters }, payload) {
            let url = getters.selectedRecord ? `admin/leaseRequests/${getters.selectedRecord.id}` : `admin/leaseRequests`
            payload.role = 'user'
            let { data } = await axios({
                method: getters.selectedRecord ? 'put' : 'post',
                url,
                data: payload
            })

            if (data.success) {
                dispatch('fetchRecords')
                commit('SET_SELECTED_RECORD', data.leaseRequest)
            }

            return data
        },

        async updateLeaseRequest({ getters }) {
            let formData = new FormData()

            let leaseRequest = getters.selectedRecord

            if (leaseRequest.type == 'commercial' && leaseRequest.buildingCompletionImage) {
                formData.append('buildingCompletionImage', leaseRequest.buildingCompletionImage)
                formData.append('crImage', leaseRequest.crImage)

                leaseRequest.buildingCompletionImage = null
                leaseRequest.crImage = null
            }
            if (leaseRequest.leaseImage) {
                formData.append('leaseImage', leaseRequest.leaseImage)
                leaseRequest.leaseImage = null
            }

            leaseRequest.agent_id = leaseRequest.agent.id
            leaseRequest.agent = null


            for (const [key, value] of Object.entries(leaseRequest)) {
                if (value !== null) {
                    formData.append(key, value)
                }
            }

            let { data } = await axios.post(`admin/leaseRequests/${leaseRequest.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })

            return data
        },

        async clearLeaseRequestsCounter({ rootState }) {
            let { data } = await axios.post('admin/leaseRequestsCounter')

            rootState.auth.user.leaseRequestsCounter = 0

            return data
        }
    }
}