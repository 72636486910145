<template>
  <li>
    <a href="#!" @click.stop="showRequest">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4 panel-info text-center text-sm-right">
          <div @click.stop class="d-inline">
            <b-form-checkbox
              class="d-inline pl-0"
              v-model="request.selected"
              :value="true"
              @change.native="toggleRequestSelection(request)"
            >
            </b-form-checkbox>
          </div>
          <!-- <div v-else class="d-inline-block" style="width: 24px"></div> -->
          {{ request.name }}
        </div>

        <div class="col-12 col-sm-6 col-md-4 panel-info text-center">
          {{ request.contact }}
        </div>

        <div class="col-12 col-sm-6 col-md-4 panel-info text-center">
          {{ getDate(request.created_at) }}
        </div>
      </div>

      <!-- <div
        v-if="showDetails"
        @click.stop="showDetails = false"
        class="container doc-row-details"
      >
        <div class="row">
          <div class="col-12 text-justify">
            {{ request.body }}
          </div>
        </div>
      </div> -->
    </a>
  </li>
</template>

<script>
import RequestsMixin from '@/mixins/requests'

export default {
  mixins: [RequestsMixin],

  data() {
    return {
      showDetails: false,
    };
  },

  methods: {
    showRequest () {
      this.selectRequest(this.request)
      this.$bvModal.show('request-modal')
    }
  },

  props: ["request"],
};
</script>