import { mapActions, mapGetters } from 'vuex';

export default {
    methods: {
        ...mapActions({
            selectAgreement: 'agreements/selectAgreement',
            updateAgreement: 'agreements/updateAgreement',
            selectAgreementByClientId: 'agreements/selectAgreementByClientId'
        })
    },

    computed: {
        ...mapGetters({
            agreements: 'agreements/agreements',
            selectedAgreement: 'agreements/selectedAgreement'
        })
    }
}