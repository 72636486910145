<template>
  <b-modal id="commission-modal">
    <template v-slot:modal-header="">
      <h5>العمولة</h5>
    </template>

    <template v-slot:default="">
      <div class="form-group row">
        <label class="col-sm-4 col-form-label text-muted text-right"
          >الموظف</label
        >
        <div class="col-sm-8">
          <multiselect
            dir="rtl"
            class="text-right"
            v-model="commission.agent"
            :options="agents"
            placeholder="حدد الموظف"
            label="name"
            track-by="name"
            selectLabel="اضغظ Enter للتحديد"
            deselectLabel="اضغط Enter لإلغاء التحديد"
          >
            <template><span slot="noResult">لا توجد نتائج</span></template>
            <template><span slot="noOptions">لا توجد خيارات</span></template>
          </multiselect>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label text-muted text-right"
          >العقد</label
        >
        <div class="col-sm-8">
          <!-- <multiselect
            dir="rtl"
            class="text-right"
            v-model="commission.agreement"
            :options="agreements"
            placeholder="حدد العقد"
            label="number"
            track-by="number"
            selectLabel="اضغظ Enter للتحديد"
            deselectLabel="اضغط Enter لإلغاء التحديد"
          >
            <template><span slot="noResult">لا توجد نتائج</span></template>
            <template><span slot="noOptions">لا توجد خيارات</span></template>
          </multiselect> -->
          <input type="text" v-model="commission.agreementNumber" class="form-control">
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label text-muted text-right"
          >المبلغ</label
        >
        <div class="col-sm-8">
          <input
            type="number"
            class="form-control"
            v-model="commission.totalAmount"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label text-muted text-right"
          >المدفوع</label
        >
        <div class="col-sm-8">
          <input
            type="number"
            class="form-control"
            v-model="commission.paidAmount"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label text-muted text-right"
          >المتبقي</label
        >
        <div class="col-sm-8">
          <input
            type="number"
            class="form-control"
            v-model="commission.remainingAmount"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label text-muted text-right"
          >التاريخ</label
        >
        <div class="col-sm-8">
          <b-form-datepicker
            v-model="commission.date"
            locale="ar"
          ></b-form-datepicker>
        </div>
      </div>
    </template>

    <template v-slot:modal-footer="">
      <button type="submit" class="btn btn-primary" @click="submit">حفظ</button>
      <a href="#" class="text-muted" @click="$bvModal.hide('commission-modal')"
        >إغلاق</a
      >
    </template>
  </b-modal>
</template>

<script>
import CommissionsMixin from "@/mixins/commissions";
import AgentsMixin from "@/mixins/agents";
import AgreementsMixin from "@/mixins/agreements";

export default {
  mixins: [CommissionsMixin, AgentsMixin, AgreementsMixin],

  data() {
    return {
      commission: {
        agent: null,
        totalAmount: null,
        paidAmount: null,
        remainingAmount: null,
        date: null,
        agreement: null
      },
    };
  },

  mounted() {
    this.buildCommission();
  },

  methods: {
    buildCommission() {
      if (this.selectedCommission) {
        this.commission = this.selectedCommission;
      } else {
        this.commission = {
          agent: null,
          totalAmount: null,
          paidAmount: null,
          remainingAmount: null,
          date: null,
          agreement: null
        };
      }
    },

    submit() {
      this.saveCommission(this.commission).then((res) => {
        if (res.success) {
            this.$bvModal.hide("commission-modal")
        } else {
            this.showSwal(res)
        }
      });
    },
  },

  watch: {
    selectedCommission() {
      this.buildCommission();
    },
  },
};
</script>