<template>
  <div>
    <div class="text-right mb-3">
      <button class="btn btn-sm btn-primary" @click="addAdTap">
        <fa icon="plus" class="ml-2" />
        <span>إضافة إعلان</span>
      </button>
    </div>

    <PanelList
      :items="ads"
      title="الإعلانات التجارية"
      :isAllSelected="isAllSelected"
      @toggle-items-selection="toggleAdsSelection()"
      @delete-selected="deleteSelectedAds()"
    >
      <AdPanelItem v-for="(ad, i) in ads" :key="i" :ad="ad" />
    </PanelList>
  </div>
</template>

<script>
import PanelList from "@/components/general/panel-list";
import AdPanelItem from "@/components/panel-items/ad";
import AdsMixin from '@/mixins/ads'

export default {
  mixins: [AdsMixin],

  methods: {
    addAdTap () {
      this.selectAd(null)
      this.$router.push({ name: 'ads.show', params: { adNumber: 'create' } })
    }
  },

  computed: {
    isAllSelected () {
      return this.selectedAds.length == this.ads.length
    }
  },
  
  components: {
    PanelList,
    AdPanelItem,
  },
};
</script>