<template>
  <li>
    <a href="#!" @click.prevent="showDiscountTap">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-8 panel-info text-center text-sm-right">
          <div
            @click.stop
            class="d-inline"
          >
            <b-form-checkbox
              class="d-inline pl-0"
              v-model="discount.selected"
              :value="true"
              @change.native="toggleDiscountSelection(discount)"
            >
            </b-form-checkbox>
          </div>
          <!-- <div v-else class="d-inline-block" style="width: 24px"></div> -->
            {{ discount.offerer }}
        </div>

        <div class="col-12 col-sm-6 col-md-4 panel-info text-center">
            <span class="text-muted small">الخصم: </span>
            <ArabicNumber :number="discount.rate" />%
        </div>
      </div>
    </a>

    
  </li>
</template>

<script>
import DiscountsMixin from '@/mixins/discounts'

export default {
  mixins: [DiscountsMixin],
  
  methods: {
    showDiscountTap () {
      this.selectDiscount(this.discount)
      this.$bvModal.show('discount-modal')
    }
  },

  props: ["discount"],
};
</script>