<template>
  <li>
    <a href="#!" @click.prevent="showCommissionTap">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-2 panel-info text-center text-sm-right">
          <div
            @click.stop
            class="d-inline"
          >
            <b-form-checkbox
              class="d-inline pl-0"
              v-model="commission.selected"
              :value="true"
              @change.native="toggleCommissionSelection(commission)"
            >
            </b-form-checkbox>
          </div>
          <div class="d-inline-block">
            <div>{{ commission.agent.name }}</div>
            <span class="text-muted small">العقد: </span>
            <span class="small">#{{ commission.agreementNumber }}</span>
          </div>
          
        </div>

        <div class="col-12 col-sm-6 col-md-2 panel-info text-center">
          <span class="text-muted small">التاريخ: </span>
          {{ getExactDateMin(commission.date) }}
        </div>

        <div class="col-12 col-sm-6 col-md-2 panel-info text-center">
          <span class="text-muted small">المبلغ: </span>
          <money :amount="commission.totalAmount" />
        </div>

        <div class="col-12 col-sm-6 col-md-2 panel-info text-center">
          <span class="text-muted small">المدفوع: </span>
          <money :amount="commission.paidAmount" />
        </div>

        <div class="col-12 col-sm-6 col-md-2 panel-info text-center">
          <span class="text-muted small">المتبقي: </span>
          <money :amount="commission.remainingAmount" />
        </div>

        <div class="col-12 col-sm-6 col-md-2 panel-info text-center">
          <!-- <span class="text-muted small">الحالة: </span> -->

          <span
            class="badge"
            :class="{
              'badge-danger': !isPaid,
              'badge-success': isPaid,
            }"
          >
            {{ isPaid ? "مسدد" : "غير مسدد" }}
          </span>
        </div>
      </div>
    </a>
  </li>
</template>

<script>
import CommissionsMixin from '@/mixins/commissions'

export default {
  mixins: [CommissionsMixin],
  
  methods: {
    showCommissionTap () {
      this.selectCommission(this.commission)
      this.$bvModal.show('commission-modal')
    }
  },

  computed: {
    isPaid() {
      return this.commission.totalAmount == this.commission.paidAmount;
    },
  },

  props: ["commission"],
};
</script>